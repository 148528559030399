<template>
  <div class="VirHomePart2" style="position: relative">
    <div class="brand_block wow animate__zoomIn" data-wow-duration="1s">
      <h1>用户运营</h1>
      <div class="block_subtitle" style="color: #999">
        全渠道、全行业、全场景SAAS产品覆盖
      </div>
    </div>
    <div
      class="tabs-has-line wow animate__fadeInUpBig"
      data-wow-duration="0.5s"
    >
      <div class="v4_user_yy_content">
        <div
          class="v4_user_yy_left box_size opacity_hide animate__animated opacity_show animate__slideInUp"
        >
          <div
            class="v4_user_yy_item"
            v-for="(item, index) in VirHomePart2_Data"
            :key="index"
          >
            <img class="v4_user_yy_item_icon" :src="item.icon" alt="" />
            <div style="margin-left: 10px">
              <div class="v4_user_yy_item_typename">{{ item.name }}</div>
              <div class="v4_user_yy_item_subtitle">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="v4_user_yy_right box_size opacity_hide animate__animated opacity_show animate__fadeInUp jks"
      >
        <img
          style="width: 850px; height: 754px"
          src="../../../assets/home/v4_main1.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  pengyiyang
 * @date  2023/02/14 18:11
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import VirHomePart2_Data from "./Data/VirHomePart2_Data";

export default {
  name: "VirHomePart2",
  data() {
    return {
      VirHomePart2_Data,
      activeKey: 0,
    };
  },
  mounted() {
    new WOW.WOW().init();
    // document.querySelectorAll('.VirHomePart2 .tabs-has-line .ant-tabs-nav>div div').forEach((ele) => {
    //   ele.addEventListener('mouseenter', this.mouseEnterTabsEvent)
    // })
  },
  beforeDestroy() {
    // document.querySelectorAll('.VirHomePart2 .tabs-has-line .ant-tabs-nav>div div').forEach((ele) => {
    //   ele.removeEventListener('mouseenter', this.mouseEnterTabsEvent)
    // })
  },
  methods: {
    mouseEnterTabsEvent(e) {
      console.log("鼠标", e);
      this.activeKey = e;
    },
  },
};
</script>

<style scoped lang="scss">
.jks {
  position: absolute;
  right: 0;
  top: 120px;
}
.VirHomePart2 {
  width: 100%;
  background: #f6f8fa;
  padding: 110px 0;
  text-align: center;
  position: relative;

  .v4_user_yy_content {
    display: flex;
    align-items: center;
    width: 1440px;
    margin: 70px auto auto;

    .v4_user_yy_left {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;

      .v4_user_yy_item {
        display: flex;
        margin-bottom: 40px;
        align-items: flex-start;
        text-align: left;
        cursor: pointer;

        &:hover {
          .v4_user_yy_item_icon {
            animation-name: heartBeat;
            animation-duration: 1s;
          }
        }

        .v4_user_yy_item_icon {
          width: 35px;
        }

        .v4_user_yy_item_typename {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #222;
        }

        .v4_user_yy_item_subtitle {
          width: 440px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666;
          line-height: 28px;
          padding: 18px 0;
        }
      }
    }

    .v4_user_yy_right {
      margin-left: 40px;
      width: 50%;
      height: 100%;

      img {
        width: 494px;
        height: 342px;
      }
    }
  }
}
</style>
