<template>
  <div class="Inthome">
    <!-- <div class="bgone">
      <div class="bgone_tit">
        <div>
          <div><h2>商城SaaS会员制运营服务</h2></div>
          <div style="width: 390px; font-size: 18px; color: #666">
            <p>
              搭建专属积分体系，为企业带来更多忠实用户，有效提高企业的拉新促活和转化能力
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <VirHomePart1></VirHomePart1>
    <VirHomePart2></VirHomePart2>
    <VirHomePart3></VirHomePart3>
    <VirHomePart4></VirHomePart4>
    <HomeFooterBanner></HomeFooterBanner>
  </div>
</template>

<script>
import WOW from "wowjs";
import BannerVideo from "@/components/BannerVideo";
import VirHomePart1 from "@/views/ProductServer/Inthome/VirHomePart1";
import VirHomePart2 from "@/views/ProductServer/Inthome/VirHomePart2";
import VirHomePart3 from "@/views/ProductServer/Inthome/VirHomePart3";
import VirHomePart4 from "@/views/ProductServer/Inthome/VirHomePart4";
import HomeFooterBanner from "@/Layouts/components/FooterBanner/footerBanner/index.vue";

/**
 * @author  XuHongli
 * @date  2022/12/28 10:12
 * @version 1.0
 * @description
 */
export default {
  name: "Inthome",
  components: {
    BannerVideo,
    VirHomePart1,
    VirHomePart2,
    VirHomePart3,
    VirHomePart4,
    HomeFooterBanner,
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.bgone {
  background: url(../../../assets/home/bg1.png) no-repeat;
  height: 450px;
  width: 100%;
  background-size: 100% 100%;
  &_tit {
    width: 1440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
