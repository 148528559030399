<template>
  <div class="VirHomePart2">
    <div class="brand_block wow animate__zoomIn" data-wow-duration="1s">
      <div class="block_maintitle">拉新促活</div>
      <div class="block_subtitle" style="color: #999">
        游戏化活动运营工具，个性化玩法提升留存，有效提升用户忠诚度
      </div>
    </div>
    <div
      class="tabs-has-line wow animate__fadeInUpBig"
      data-wow-duration="0.5s"
    >
      <div class="v4_pull_new_content">
        <div
          class="v4_pull_new_left opacity_hide animate__animated opacity_show animate__slideInUp"
        >
          <div
            class="v4_pull_new_item"
            v-for="(item, index) in VirHomePart3_DataLeft"
            :key="index"
          >
            <img class="v4_pull_new_item_icon" :src="item.icon" alt="" />
            <div style="margin-left: 10px">
              <div class="v4_pull_new_item_typename">{{ item.name }}</div>
              <div class="v4_pull_new_item_subtitle">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="v4_pull_new_center">
          <img
            class="img1 animate__zoomIn animation-duration-1 wow"
            src="../../../assets/home/img1.png"
            alt=""
          />

          <img
            class="img2 animate__zoomIn animation-duration-1 wow"
            src="../../../assets/home/img2.png"
            alt=""
          />

          <img
            class="img3 animate__zoomIn animation-duration-1 wow"
            src="../../../assets/home/bgR8.png"
            alt=""
          />
        </div>
        <div
          class="v4_pull_new_right opacity_hide animate__animated opacity_show animate__zoomIn"
        >
          <div
            class="v4_pull_new_item"
            v-for="(item, index) in VirHomePart3_DataRight"
            :key="index"
          >
            <img class="v4_pull_new_item_icon" :src="item.icon" alt="" />
            <div style="margin-left: 10px">
              <div class="v4_pull_new_item_typename">{{ item.name }}</div>
              <div class="v4_pull_new_item_subtitle">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  pengyiyang
 * @date  2023/02/14 18:11
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import {
  VirHomePart3_DataRight,
  VirHomePart3_DataLeft,
  VirHomePart3_DataCenter,
} from "./Data/VirHomePart3_Data";

export default {
  name: "VirHomePart3",
  data() {
    return {
      VirHomePart3_DataLeft,
      VirHomePart3_DataRight,
      VirHomePart3_DataCenter,
      activeKey: 0,
    };
  },
  mounted() {
    new WOW.WOW().init();
    document
      .querySelectorAll(".VirHomePart2 .tabs-has-line .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.addEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  beforeDestroy() {
    document
      .querySelectorAll(".VirHomePart2 .tabs-has-line .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.removeEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  methods: {
    mouseEnterTabsEvent(e) {
      console.log("鼠标", e);
      this.activeKey = e;
    },
  },
};
</script>

<style scoped lang="scss">
.VirHomePart2 {
  width: 100%;
  background: #fff;
  padding: 120px 0;
  text-align: center;
  position: relative;

  .v4_pull_new_content {
    display: flex;
    align-items: center;
    width: 1280px;
    margin: 90px auto auto;

    .v4_pull_new_left,
    .p_nav,
    .constent,
    .v4_pull_new_right {
      width: 30%;
      display: flex;
      flex-direction: column;

      .v4_pull_new_item {
        display: flex;
        margin-bottom: 40px;
        align-items: flex-start;
        text-align: left;
        cursor: pointer;

        &:hover {
          .v4_pull_new_item_icon {
            animation-name: heartBeat;
            animation-duration: 1s;
          }
        }

        .v4_pull_new_item_icon {
          width: 54px;
        }

        .v4_pull_new_item_typename {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #222;
          cursor: pointer;
        }

        .v4_pull_new_item_subtitle {
          width: 310px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666;
          line-height: 28px;
          padding: 18px 0;
        }
      }
    }

    .v4_pull_new_center {
      width: 40%;
      display: flex;
      justify-content: center;
      position: relative;

      .img1 {
        position: absolute;
        bottom: 110px;
        left: 30px;
        width: 102px;
        height: 89px;
      }

      .img2 {
        position: absolute;
        bottom: 90px;
        right: 30px;
        width: 100px;
        height: 118px;
      }

      .img3 {
        position: absolute;
        bottom: -180px;
        // width: 750px;
        height: 320px;
      }
    }
  }
}
</style>
