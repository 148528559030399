/**
 * @author  pegyiyang
 * @date  2023/02/14 14:34
 * @version 1.0
 * @description
 */

export const VirHomePart3_DataLeft = [
    {
        icon: '/mp/liu.png',
        name: '活跃留存',
        text: '创新签到、积分任务和会员权益，提高用户留存搭建完善的会员积分体系'
    }, {
        icon: '/mp/liebian.png',
        name: '裂变拉新',
        text: '火爆拼团、趣味分享，社交裂变提高拉新效率'
    }, {
        icon: '/mp/tigao.png',
        name: '提高收入',
        text: '一级分销、发券宝、提高销量、增加收入'
    },

]
export const VirHomePart3_DataRight = [
    {
        icon: '/mp/lizhi.png',
        name: '奖励玩法',
        text: '通过积分、优惠券、现金等利益驱动用户行为'
    }, {
        icon: '/mp/dengji.png',
        name: '等级排行',
        text: '荣誉竞争驱动用户行为，好友竞争提升用户活跃指标'
    }, {
        icon:'/mp/peiyang.png',
        name: '培养养成',
        text: '养成式游戏玩法，培养用户成就感，提升用户活跃'
    },

]
export const VirHomePart3_DataCenter = [
    {
        icon: 'https://cdn.jingpingo.com/wanjingai/public/mp/pages/ProductServer/VirHome/v4_pullNew_yun.png',
        className: ['img1', 'animate__zoomIn', 'animation-duration-1', 'wow']
    }, {
        icon: 'https://cdn.jingpingo.com/wanjingai/public/mp/pages/ProductServer/VirHome/v4_pullNew_zhu.png',
        className: ['img2', 'animate__zoomIn', 'animation-duration-1', 'wow']
    }, {
        icon: 'https://cdn.jingpingo.com/wanjingai/public/mp/pages/ProductServer/VirHome/v4_pullNew_you.png',
        className: ['img3', 'animate__zoomIn', 'animation-duration-1', 'wow']
    },

]
