<template>
  <div class="VirHomePart2">
    <div class="brand_block wow animate__zoomIn" data-wow-duration="1s">
      <div class="block_maintitle">创意签到</div>
      <div class="block_subtitle" style="color: #999">
        多种签到和积分任务组合，趣味性玩法提升用户活跃
      </div>
    </div>
    <div
      class="tabs-has-line wow animate__fadeInUpBig"
      data-wow-duration="0.5s"
    >
      <div class="v4_sign_content">
        <div class="sign-left">
          <div class="sign-tab-box">
            <div
              :class="[
                'sign-tab',
                activeKey === index ? 'sign-tab-active' : '',
              ]"
              v-for="(item, index) in VirHomePart4_DataTop"
              :key="index"
              @mouseenter="mouseEnterTabsEvent(index)"
            >
              <img
                class="sign-icon"
                :src="activeKey === index ? item.iconSelect : item.icon"
                alt=""
              />
              <p class="headline">{{ item.name }}</p>
            </div>
          </div>

          <div
            :class="[
              'sign-tab-cont',
              activeKey === index ? 'sign-tab-cont-active' : '',
            ]"
            v-for="(item, index) in VirHomePart4_DataTop"
            :key="index"
          >
            <p class="sign-tab-title">{{ item.name }}</p>
            <p class="sign-tab-subtitle">{{ item.text }}</p>
            <p class="sign-tab-link">
              <a
                style="color: #ff6a00"
                href="https://console.wanjingai.com/user/login"
                target="_blank"
                >立即体验 &gt;
              </a>
            </p>
          </div>
        </div>

        <div class="sign-right">
          <div class="bg"></div>
          <div
            :class="['img-box', activeKey === index ? 'img-box-active' : '']"
            v-for="(item, index) in VirHomePart4_DataTop"
            :key="index"
          >
            <div>
              <img
                :class="['img-bg', activeKey === index ? 'img-bg-active' : '']"
                :src="item.img1"
                alt=""
              />
              <img
                :class="[
                  'img-top',
                  activeKey === index ? 'img-top-active' : '',
                ]"
                :src="item.img2"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  pengyiyang
 * @date  2023/02/14 18:11
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import VirHomePart4_DataTop from "./Data/VirHomePart4_Data";

export default {
  name: "VirHomePart4",

  data() {
    return {
      VirHomePart4_DataTop,
      activeKey: 0,
    };
  },
  mounted() {
    new WOW.WOW().init();
    document
      .querySelectorAll(
        ".VirHomePart2 .tabs-has-line .sign-left .sign-tab-box>div"
      )
      .forEach((ele) => {
        ele.addEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  beforeDestroy() {
    document
      .querySelectorAll(
        ".VirHomePart2 .tabs-has-line .sign-left .sign-tab-box>div"
      )
      .forEach((ele) => {
        ele.removeEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  methods: {
    mouseEnterTabsEvent(e) {
      console.log("鼠标", e);
      if (!isNaN(e)) {
        this.activeKey = e;
      }
      console.log("this.activeKey", this.activeKey);
    },
  },
};
</script>

<style scoped lang="scss">
.VirHomePart2 {
  width: 100%;
  background: #f6f8fa;
  padding: 120px 0;
  text-align: center;
  position: relative;

  .v4_sign_content {
    display: flex;
    align-items: center;
    width: 1440px;
    margin: auto;

    .sign-left {
      .sign-tab-box {
        display: flex;
        align-items: center;

        .sign-tab {
          width: 136px;
          height: 136px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          transition: all 0.3s ease-out;
          background: transparent;

          .sign-icon {
            width: 50px;
            height: 65px;
          }

          .headline {
            font-size: 18px;
            color: #666;
            margin-top: 18px;
          }
        }

        .sign-tab-active {
          background: #fff;
          box-shadow: -4px 0 20px 0 hsl(0deg 0% 87% / 50%);

          .headline {
            color: #ff6a00;
          }
        }
      }

      .sign-tab-cont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 60px;
        display: none;
        text-align: left;

        .sign-tab-title {
          font-size: 26px;
          color: #222;
          font-weight: 400;
        }

        .sign-tab-subtitle {
          width: 486px;
          text-align: left;
          font-size: 18px;
          color: #666;
          font-weight: 400;
          margin: 40px 0 40px;
          line-height: 28px;
          min-height: 56px;
        }

        .sign-tab-link {
          font-size: 18px;
          color: #ff6a00;
          font-weight: 400;
        }
      }

      .sign-tab-cont-active {
        display: block;
        -webkit-animation: tabCont-c5194c98 0.3s ease;
        animation: tabCont-c5194c98 0.3s ease;
      }
    }

    .sign-right {
      flex: 1;
      height: 538px;
      position: relative;
      margin-left: 140px;

      .bg {
        width: 380px;
        height: 200px;
        background-color: #ff6a00;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) skewY(15deg);
        z-index: 0;
      }

      .img-box > div {
        width: 256px;
        height: 538px;
        border: 6px solid #222;
        overflow: hidden;
        border-radius: 40px;
        margin-left: 160px;
      }

      .img-box {
        position: relative;
        width: 100%;
        height: 100%;
        display: none;

        .img-bg {
          width: 256px;
          height: 538px;
          transform: scale(1);
          margin: -5px 0 0 -6px;
          display: none;
        }

        .img-bg-active {
          display: block;
          -webkit-animation: imgScale-c5194c98 0.3s ease;
          animation: imgScale-c5194c98 0.3s ease;
        }

        .img-top {
          width: 340px;
          height: 240px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(1);
          z-index: 3;
          display: none;
        }

        .img-top-active {
          display: block;
          -webkit-animation: imgTopScale-c5194c98 1s ease;
          animation: imgTopScale-c5194c98 1s ease;
        }
      }

      .img-box-active {
        display: block;
      }
    }
  }
}

@keyframes tabCont-c5194c98 {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes imgScale-c5194c98 {
  0% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes imgScale-c5194c98 {
  0% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes imgTopScale-c5194c98 {
  0% {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
