/**
 * @author  pegyiyang
 * @date  2023/02/14 14:34
 * @version 1.0
 * @description
 */

const VirHomePart2_Data = [
    {
        icon: require('@/assets/home/1.png'),
        name: '涉及渠道',
        text: '提供全渠道的运营服务：APP、直播、小程序、网页、公众号、淘客平台'
    }, {
        icon: require('@/assets/home/2.png'),
        name: '使用场景',
        text: '丰富的互动玩法覆盖多场景：裂变、拉新、引流、留存、积分消耗、促进交易'
    }, {
        icon: require('@/assets/home/3.png'),
        name: '覆盖行业',
        text: '为多个行业提供用户运营解决方案：互联网、电商、教育、零售、保险、银行等'
    },

]
export default VirHomePart2_Data
