var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"VirHomePart2"},[_vm._m(0),_c('div',{staticClass:"tabs-has-line wow animate__fadeInUpBig",attrs:{"data-wow-duration":"0.5s"}},[_c('div',{staticClass:"v4_sign_content"},[_c('div',{staticClass:"sign-left"},[_c('div',{staticClass:"sign-tab-box"},_vm._l((_vm.VirHomePart4_DataTop),function(item,index){return _c('div',{key:index,class:[
              'sign-tab',
              _vm.activeKey === index ? 'sign-tab-active' : '',
            ],on:{"mouseenter":function($event){return _vm.mouseEnterTabsEvent(index)}}},[_c('img',{staticClass:"sign-icon",attrs:{"src":_vm.activeKey === index ? item.iconSelect : item.icon,"alt":""}}),_c('p',{staticClass:"headline"},[_vm._v(_vm._s(item.name))])])}),0),_vm._l((_vm.VirHomePart4_DataTop),function(item,index){return _c('div',{key:index,class:[
            'sign-tab-cont',
            _vm.activeKey === index ? 'sign-tab-cont-active' : '',
          ]},[_c('p',{staticClass:"sign-tab-title"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"sign-tab-subtitle"},[_vm._v(_vm._s(item.text))]),_vm._m(1,true)])})],2),_c('div',{staticClass:"sign-right"},[_c('div',{staticClass:"bg"}),_vm._l((_vm.VirHomePart4_DataTop),function(item,index){return _c('div',{key:index,class:['img-box', _vm.activeKey === index ? 'img-box-active' : '']},[_c('div',[_c('img',{class:['img-bg', _vm.activeKey === index ? 'img-bg-active' : ''],attrs:{"src":item.img1,"alt":""}}),_c('img',{class:[
                'img-top',
                _vm.activeKey === index ? 'img-top-active' : '',
              ],attrs:{"src":item.img2,"alt":""}})])])})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand_block wow animate__zoomIn",attrs:{"data-wow-duration":"1s"}},[_c('div',{staticClass:"block_maintitle"},[_vm._v("创意签到")]),_c('div',{staticClass:"block_subtitle",staticStyle:{"color":"#999"}},[_vm._v(" 多种签到和积分任务组合，趣味性玩法提升用户活跃 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"sign-tab-link"},[_c('a',{staticStyle:{"color":"#ff6a00"},attrs:{"href":"https://console.wanjingai.com/user/login","target":"_blank"}},[_vm._v("立即体验 > ")])])
}]

export { render, staticRenderFns }