<template>
  <div class="VirHomePart2">
    <div class="brand_block wow animate__zoomIn" data-wow-duration="1s">
      <h1>核心服务</h1>
      <div class="block_subtitle" style="color: #666666; font-size: 18px">
        助力企业流量运营营销
      </div>
    </div>
    <div
      class="tabs-has-line wow animate__fadeInUpBig"
      data-wow-duration="0.5s"
    >
      <a-tabs v-model="activeKey">
        <a-tab-pane
          :key="item.name"
          :tab="item.name"
          style="height: 500px; font-size: 35px"
          v-for="item in VirHomePart1_Data"
        >
          <div class="advantage_content" v-if="activeKey === item.name">
            <div
              class="advantage_title wow animate__fadeInRight animation-duration-1"
              data-wow-duration="2s"
            >
              <div class="advantage_headline">{{ item.name }}</div>
              <div class="advantage_subtitle">
                {{ item.title }}
              </div>
              <div style="margin-top: 50px">
                <a
                  style="color: #ff6a00; font-size: 18px"
                  href="javascript:void(0)"
                  >了解详情 <a-icon type="right"
                /></a>
              </div>
            </div>
            <div class="advantage_img advantage_box">
              <img
                v-for="(it, ind) in item.imgs"
                :key="ind"
                :class="it.className"
                :src="it.url"
              />
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/23 18:57
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import VirHomePart1_Data from "./Data/VirHomePart1_Data";
export default {
  name: "VirHomePart1",
  data() {
    return {
      VirHomePart1_Data,
      activeKey: "培养企业忠诚用户",
    };
  },
  mounted() {
    new WOW.WOW().init();
    document
      .querySelectorAll(".VirHomePart2 .tabs-has-line .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.addEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  beforeDestroy() {
    document
      .querySelectorAll(".VirHomePart2 .tabs-has-line .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.removeEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  methods: {
    mouseEnterTabsEvent(e) {
      this.activeKey = e.target.innerText;
    },
  },
};
</script>

<style scoped lang="scss">
.VirHomePart2 {
  width: 100%;
  background: #fff;
  padding: 120px 0;
  text-align: center;
  position: relative;
}

//.advantage_img {
//  .first {
//    position: absolute;
//    width: 230px;
//    height: 260px;
//    z-index: 10;
//  }
//
//  .third {
//    position: absolute;
//    left: 0;
//    top: 0;
//    width: 144px;
//    z-index: 9;
//  }
//
//  .last {
//    position: absolute;
//    left: 50%;
//    top: 5%;
//    width: 106px;
//    //height: 387px;
//  }
//}
</style>
<style scoped lang="scss">
.advantage_content {
  display: flex;
  box-sizing: border-box;
  margin-top: 30px;
  align-items: center;
}

.advantage_img {
  &.advantage_box {
    position: relative;
    background-size: 70% 100%;
    padding-top: 100px;
    box-sizing: border-box;

    //img {
    //  width: 230px;
    //  height: 260px;
    //}

    .img_box_center {
      position: absolute;
      //top: 0;
      //left: 10%;
      width: 230px;
      height: 230px;
      z-index: 10;
    }

    .img_first {
      position: absolute;
      top: 78px;
      left: 122px;
      width: 144px;
      z-index: 9;
    }

    .img_third {
      position: absolute;
      top: 72px;
      right: 218px;
      width: 106px;
      z-index: 9;
    }

    .img_three {
      position: absolute;
      right: 91px;
      top: 127px;
      width: 148px;
    }

    .img_four {
      position: absolute;
      bottom: -4px;
      right: 200px;
      width: 84px;
    }

    .img_five {
      position: absolute;
      bottom: 32px;
      left: 109px;
      width: 122px;
    }
  }
}
</style>
<style lang="scss" scoped>
// TODOS 背景替换
.advantage_img {
  &.advantage_dz {
    background-position: top;
    background-size: 82% 100%;
    transition: all 2s;
    background-image: url("https://resource.duobaoyu.com.cn/website/bj_img_010.png");

    .advantage_dz_img {
      position: absolute;
      top: -30px;
      transition: all 1s;
      opacity: 1;
      animation: 1s zoomInReverse;
    }
  }
}
</style>
