/**
 * @author  pegyiyang
 * @date  2023/02/14 14:34
 * @version 1.0
 * @description
 */

const VirHomePart4_DataTop = [
    {
        iconSelect: require('@/assets/home/aa.png'),
        icon: '/mp/pages/ProductServer/VirHome/rl_img_001.png',
        name: '日历签到',
        text: '用户连续签到后可获取奖励，签到次数越多，奖励越多，从而养成用户签到习惯',
        img1: '/mp/v4_sign_rl_2.png',
        img2: '/mp/v4_sign_rlt_2.png',
    }, {
        iconSelect: require('@/assets/home/bb.png'),
        icon: '/mp/pages/ProductServer/VirHome/rl_002.png',
        name: '集卡签到',
        text: '累计签到收集卡片，兑换大奖卡片结合产品特性，场景化设计，增加趣味性',
        img1: '/mp/v4_sign_jk_3.png',
        img2: '/mp/v4_sign_jkt_3.png',
    }, {
        iconSelect: require('@/assets/home/cc.png'),
        icon: '/mp/pages/ProductServer/VirHome/rl_003.png',
        name: '抽奖签到',
        text: '签到 + 抽奖，在签到的同时增加奖品机制提高用户活跃度',
        img1: '/mp/v4_sign_cj_3.png',
        img2: '/mp/v4_sign_cjt_2.png',
    }, {
        iconSelect: require('@/assets/home/ee.png'),
        icon: '/mp/pages/ProductServer/VirHome/rl_004.png',
        name: '养成式签到',
        text: '游戏化签到，通过用户与卡通宠物的情感纽带提升用户活跃',
        img1: '/mp/v4_sign_yc_2.png',
        img2: '/mp/v4_sign_yct_2.png',
    }, {
        iconSelect: require('@/assets/home/ff.png'),
        icon: '/mp/pages/ProductServer/VirHome/rl_005.png',
        name: '契约签到',
        text: '引导用户前期投入积分，刺激用户不断访问',
        img1: '/mp/v4_sign_qy_2.png',
        img2: '/mp/v4_sign_qyt_2.png',
    },

]
export default VirHomePart4_DataTop
