/**
 * @author  pegyiyang
 * @date  2023/02/14 14:34
 * @version 1.0
 * @description
 */

const VirHomePart1_Data = [
    {
        name: '培养企业忠诚用户',
        title: '通过游戏化运营、成长值会员和付费会员运营等手段，提升用户粘性、复购率和用户生命周期的总体价值。',
        imgs: [
            {
                url: require('@/assets/home/z1.png'),
                className: ['img_box_center', 'animate__zoomIn', 'animation-duration-1', 'wow'],
            },
            {
                url: '/mp/VirHome/v4_main4_1.png',
                className: ['img_first', 'animation-duration-1', 'animate__fadeInBottomRight', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main4_2.png',
                className: ['img_third', 'animation-duration-1', 'animate__fadeInBottomLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main4_3.png',
                className: ['img_three', 'animation-duration-1', 'animate__fadeInLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main4_4.png',
                className: ['img_four', 'animation-duration-1', 'animate__fadeInTopLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main4_5.png',
                className: ['img_five', 'animation-duration-1', 'animate__fadeInTopRight', 'wow'],
            },

        ]
    }, {
        name: '促进用户活跃',
        title: '积分游戏助力商家提升用户活跃度。如：签到任务、现金红包、多种抽奖玩法。',
        imgs: [
            {
                url: require('@/assets/home/z2.png'),
                className: ['img_box_center', 'animate__zoomIn', 'animation-duration-1', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main2_1.png',
                className: ['img_first', 'animation-duration-1', 'animate__fadeInBottomRight', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main2_2.png',
                className: ['img_third', 'animation-duration-1', 'animate__fadeInBottomLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main2_3.png',
                className: ['img_three', 'animation-duration-1', 'animate__fadeInLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main2_4.png',
                className: ['img_four', 'animation-duration-1', 'animate__fadeInTopLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main2_5.png',
                className: ['img_five', 'animation-duration-1', 'animate__fadeInTopRight', 'wow'],
            },

        ]
    }, {
        name: '提高用户转化',
        title: '多种独具优势产品相互配合，形成运营生态体系让企业多方位触达、影响用户的行为。',
        imgs: [
            {
                url: require('@/assets/home/z3.png'),
                className: ['img_box_center', 'animate__zoomIn', 'animation-duration-1', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main3_1.png',
                className: ['img_first', 'animation-duration-1', 'animate__fadeInBottomRight', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main3_2.png',
                className: ['img_third', 'animation-duration-1', 'animate__fadeInBottomLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main3_3.png',
                className: ['img_three', 'animation-duration-1', 'animate__fadeInLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main3_4.png',
                className: ['img_four', 'animation-duration-1', 'animate__fadeInTopLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main3_5.png',
                className: ['img_five', 'animation-duration-1', 'animate__fadeInTopRight', 'wow'],
            },

        ]
    }, {
        name: '精细化运营',
        title: '通过会员精细化运营，帮助商家完成用户标签化管理和分层，实现精准营销，提高企业效益。',
        imgs: [
            {
                url: require('@/assets/home/z4.png'),
                className: ['img_box_center', 'animate__zoomIn', 'animation-duration-1', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main4_6.png',
                className: ['img_first', 'animation-duration-1', 'animate__fadeInBottomRight', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main4_7.png',
                className: ['img_third', 'animation-duration-1', 'animate__fadeInBottomLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main4_8.png',
                className: ['img_three', 'animation-duration-1', 'animate__fadeInLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main4_9.png',
                className: ['img_four', 'animation-duration-1', 'animate__fadeInTopLeft', 'wow'],
            }, {
                url: '/mp/VirHome/v4_main4_10.png',
                className: ['img_five', 'animation-duration-1', 'animate__fadeInTopRight', 'wow'],
            },

        ]
    },
]

export default VirHomePart1_Data
